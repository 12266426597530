<template>
  <div class="project-page">
    <div class="project-page__container">
      <div class="page__transit-1 content--transition">
        <div class="project-page__breadcrumbs">
          <router-link to="/projects" class="project-page__breadcrumbs_link">Projects</router-link>
          > <span class="project-page__breadcrumbs_breadcrumb">{{ $t('projects.mainTitle') }}</span>
        </div>

        <div class="project">
          <div class="project__header">
            <p class="project__title">{{ $t('projects.mainTitle') }}</p>
            <p class="project__description">
              {{ $t('projects.mainText1') }}<br>
              {{ $t('projects.mainText2') }}
            </p>
          </div>

          <div class="universal__line">
            <div class="universal__line_stripe"></div>
          </div>

          <div class="project__content">
            <div class="project__col">
              <div class="project__col_title h5">{{ $t('projects.aboutProject') }}</div>
            </div>

            <div class="project__col">
              <div class="project__col_list">
                <div class="project__col_list-col">
                  <div class="project__col_list-item">
                    <div class="project__col_list-text h5">
                      <span>{{ $t('projects.mainLocation') }}</span>
                      {{ $t('projects.mainLocationText') }}
                    </div>
                  </div>

                  <div class="project__col_list-item">
                    <div class="project__col_list-text h5">
                      <span>{{ $t('projects.mainScope') }}</span>
                      {{ $t('projects.mainScopeText') }}
                    </div>
                  </div>
                </div>

                <div class="project__col_list-col">
                  <div class="project__col_list-item">
                    <div class="project__col_list-text project__col_list-text-column h5">
                        <span>{{ $t('projects.mainFeatures') }}</span>
                        <br>
                        <p class="project__col_list-text-feature">
                          {{ $t('projects.mainFeaturesText') }}
                        </p>
                        <p class="project__col_list-text-feature">
                          {{ $t('projects.mainFeaturesText2') }}
                        </p>
                    </div>
                  </div>

                  <div class="project__col_list-item">
                    <div class="project__col_list-text h5">
                      <span>{{ $t('projects.mainFunction') }}</span>
                      {{ $t('projects.mainFunctionText') }}
                    </div>
                  </div>

                  <div class="project__col_list-item">
                    <div class="project__col_list-text h5">
                      <span>{{ $t('projects.mainStatus') }}</span>
                      {{ $t('projects.mainStatusText') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="project__images project__images_pop-up page__transit-2" @click="popUpAction(true)">
            <div class="project__images_col">
              <lazy-background class="project__images_col-bg" :src="require('../../assets/img/projects/main/pr-1' + '.png')" >
                <div slot="content" slot-scope="{ visible }">
                  <div v-show="visible" class="loader">
                    <svg class="circular-loader" viewBox="25 25 50 50" >
                      <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#2E6095" stroke-width="5" />
                    </svg>
                  </div>
                </div>
              </lazy-background>
            </div>
            <div class="project__images_col">
              <div class="project__images_item">
                <div class="project__images_block">
                  <lazy-background class="project__images_block-bg" :src="require('../../assets/img/projects/main/pr-2' + '.png')" >
                    <div slot="content" slot-scope="{ visible }">
                      <div v-show="visible" class="loader">
                        <svg class="circular-loader" viewBox="25 25 50 50" >
                          <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#2E6095" stroke-width="5" />
                        </svg>
                      </div>
                    </div>
                  </lazy-background>
                </div>
                <div class="project__images_block">
                  <lazy-background class="project__images_block-bg" :src="require('../../assets/img/projects/main/pr-3' + '.png')" >
                    <div slot="content" slot-scope="{ visible }">
                      <div v-show="visible" class="loader">
                        <svg class="circular-loader" viewBox="25 25 50 50" >
                          <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#2E6095" stroke-width="5" />
                        </svg>
                      </div>
                    </div>
                  </lazy-background>
                </div>
              </div>
              <div class="project__images_item">
                <div class="project__images_block">
                  <lazy-background class="project__images_block-bg" :src="require('../../assets/img/projects/main/pr-4' + '.png')" >
                    <div slot="content" slot-scope="{ visible }">
                      <div v-show="visible" class="loader">
                        <svg class="circular-loader" viewBox="25 25 50 50" >
                          <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#2E6095" stroke-width="5" />
                        </svg>
                      </div>
                    </div>
                  </lazy-background>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div
      class="modal"
      @click="popUpAction(false)"
      :class="{ 'modal--active' : this.modalSlider == true  }"
    >
      <div class="modal__content">
        <div class="modal__content_close">
          <div class="modal__content_close-button" @click="popUpAction(false)">
            <svg
              class="modal__content_close-button-svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                class="modal__content_close-button-line"
                x1="0.75"
                y1="-0.75"
                x2="26.549"
                y2="-0.75"
                transform="matrix(0.680631 0.732627 -0.638063 0.769984 0 1)"
                stroke="#7B7B7B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <line
                class="modal__content_close-button-line"
                x1="0.75"
                y1="-0.75"
                x2="26.5214"
                y2="-0.75"
                transform="matrix(-0.681635 0.731692 0.639103 0.769121 20 1)"
                stroke="#7B7B7B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <hooper
          group="group1"
          :itemsToShow="1"
          :centerMode="false"
          :infiniteScroll="true"
          class="modal__slider-top"
        >
          <slide class="slider__slide" v-for="(item, indx) in mainproject" :key="indx">
            <lazy-background class="slider__blocks_block" :src="require('../../assets/img/projects/main-project/pr-' + item.imgsrc + '.png')" >
              <div slot="content" slot-scope="{ visible }">
                <div v-show="visible" class="loader">
                  <svg class="circular-loader" viewBox="25 25 50 50" >
                    <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#2E6095" stroke-width="5" />
                  </svg>
                </div>
              </div>
            </lazy-background>
            <div class="slider__blocks_text h2">{{ item.imgText }}</div>
          </slide>
          <hooper-pagination class="slider__counter" slot="hooper-addons" mode="fraction"></hooper-pagination>
        </hooper>

        <hooper
          group="group1"
          :settings="bottomHooperSettings"
          class="modal__slider-bottom"
          ref="bottomSlider"
        >
          <slide
            class="slider__slide"
            v-for="(item, indx) in mainproject"
            :key="indx"
            :data-counter="`${indx}`"
          >
            <div
              @click="bottomSlideClickHandler(indx)"
            >
              <lazy-background class="slider__blocks_block" :src="require('../../assets/img/projects/main-project/pr-' + item.imgsrc + '.png')" >
                <div slot="content" slot-scope="{ visible }">
                  <div v-show="visible" class="loader loader--small">
                    <svg class="circular-loader" viewBox="25 25 50 50" >
                      <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#2E6095" stroke-width="5" />
                    </svg>
                  </div>
                </div>
              </lazy-background>
            </div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../../components/Footer'

import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'

export default {
  components: {
    Footer,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data () {
    return {
      themeClass: '--dark',
      modalSlider: false,
      blocks: [
        'page__transit-2'
      ],
      offsets: [],
      windowHeight: 0,
      hooperSettings: {
        infiniteScroll: true,
        centerMode: false,
        wheelControl: false,
        breakpoints: {
          2400: {
            itemsToShow: 1
          },
          475: {
            itemsToShow: 1
          }
        }
      },
      bottomHooperSettings: {
        infiniteScroll: true,
        centerMode: true,
        autoPlay: false,
        playSpeed: 3500,
        breakpoints: {
          1441: {
            itemsToShow: 11
          },
          1201: {
            itemsToShow: 9
          },
          993: {
            itemsToShow: 9
          },
          801: {
            itemsToShow: 8
          },
          601: {
            itemsToShow: 6
          },
          0: {
            itemsToShow: 6
          }
        }
      },
      mainproject: [
        {
          imgsrc: '1',
          imgText: 'Qatar Petroleum Disctrict - QPD'
        },
        {
          imgsrc: '2',
          imgText: 'Drawings Sample'
        },
        {
          imgsrc: '3',
          imgText: 'Lusail Stadium'
        },
        {
          imgsrc: '4',
          imgText: 'Site Photo'
        },
        {
          imgsrc: '5',
          imgText: 'Al-Bayt Stadium'
        },
        {
          imgsrc: '6',
          imgText: 'Al-Bayt Stadium'
        },
        {
          imgsrc: '7',
          imgText: 'Site Photo'
        },
        {
          imgsrc: '8',
          imgText: 'Tawar Mall'
        },
        {
          imgsrc: '9',
          imgText: 'Tawar Mall'
        },
        {
          imgsrc: '10',
          imgText: 'Tawar Mall'
        },
        {
          imgsrc: '11',
          imgText: 'Tawar Mall'
        },
        {
          imgsrc: '12',
          imgText: 'Drawing Sample'
        },
        {
          imgsrc: '13',
          imgText: 'Doha Festival City Mall'
        },
        {
          imgsrc: '14',
          imgText: 'Doha Festival City Mall'
        },
        {
          imgsrc: '15',
          imgText: 'Doha Festival City Mall'
        },
        {
          imgsrc: '16',
          imgText: 'Snow Dunes'
        },
        {
          imgsrc: '17',
          imgText: 'Qatar Petroleum Disctrict - QPD'
        },
        {
          imgsrc: '18',
          imgText: 'Drawing Sample'
        },
        {
          imgsrc: '19',
          imgText: 'Qatar Petroleum Disctrict - QPD'
        },
        {
          imgsrc: '20',
          imgText: '3D render Sample'
        },
        {
          imgsrc: '21',
          imgText: 'BP Oman Khazzan'
        },
        {
          imgsrc: '22',
          imgText: 'BP Oman Khazzan'
        },
        {
          imgsrc: '23',
          imgText: 'Site Photo'
        },
        {
          imgsrc: '24',
          imgText: 'Site Photo'
        },
        {
          imgsrc: '25',
          imgText: 'Site Photo'
        },
        {
          imgsrc: '26',
          imgText: 'Site Photo'
        },
        {
          imgsrc: '27',
          imgText: 'Lusail Stadium'
        },
        {
          imgsrc: '28',
          imgText: 'Lusail Stadium'
        },
        {
          imgsrc: '29',
          imgText: 'Lusail Stadium'
        },
        {
          imgsrc: '30',
          imgText: '3D Render Sample'
        },
        {
          imgsrc: '31',
          imgText: 'Energy City Qatar'
        },
        {
          imgsrc: '32',
          imgText: 'Energy City Qatar'
        },
        {
          imgsrc: '33',
          imgText: 'Site Photo'
        },
        {
          imgsrc: '34',
          imgText: 'Production Photo'
        },
        {
          imgsrc: '35',
          imgText: 'Qatar Academy Expansion Project'
        },
        {
          imgsrc: '36',
          imgText: 'Qatar University'
        },
        {
          imgsrc: '37',
          imgText: 'Qatar University'
        },
        {
          imgsrc: '38',
          imgText: 'Qatar University'
        },
        {
          imgsrc: '39',
          imgText: 'Drawings Sample'
        },
        {
          imgsrc: '40',
          imgText: 'Drawings Sample'
        },
        {
          imgsrc: '41',
          imgText: 'Le Mirage'
        },
        {
          imgsrc: '42',
          imgText: 'Le Mirage'
        },
        {
          imgsrc: '43',
          imgText: 'Site Photo'
        },
        {
          imgsrc: '44',
          imgText: 'Ezdan Palace Hotel'
        },
        {
          imgsrc: '45',
          imgText: 'Site Photo'
        },
        {
          imgsrc: '46',
          imgText: 'Lusail Light Rail Transit'
        },
        {
          imgsrc: '47',
          imgText: 'Q-Rail Goldline'
        },
        {
          imgsrc: '48',
          imgText: 'Q-Rail Goldline'
        },
        {
          imgsrc: '49',
          imgText: 'Drawing Sample'
        },
        {
          imgsrc: '50',
          imgText: 'Hamad Port'
        },
        {
          imgsrc: '51',
          imgText: 'Hamad Port'
        },
        {
          imgsrc: '52',
          imgText: 'Hamad Port'
        },
        {
          imgsrc: '53',
          imgText: 'Drawings Sample'
        },
        {
          imgsrc: '54',
          imgText: 'PDO Project Marmul'
        }
      ]
    }
  },
  methods: {
    popUpAction (condition) {
      if (condition) {
        this.modalSlider = true

        this.$emit('headerDisplay', {
          headerDisplay: false,
          headerDisplayDelay: 0
        })

        document.body.style.overflow = 'hidden'
      } else {
        if (
          event.target.classList.contains('modal') ||
          event.target.classList.contains('modal__content_close-button-svg') ||
          event.target.classList.contains('modal__content_close-button-line')
        ) {
          this.modalSlider = false
          this.$emit('headerDisplay', {
            headerDisplay: true
          })

          document.body.style.overflow = ''
        }
      }
    },
    windowHeightCounter () {
      this.windowHeight = window.innerHeight
    },
    scrollContentTransition (event) {
      let winScrollY = this.$parent.$data.topOffset + 0.65 * window.innerHeight
      let transitionEl

      for (let i = 0; i < this.offsets.length; i++) {
        if (winScrollY > this.offsets[i]) {
          transitionEl = document.querySelector(`.${this.blocks[i]}`)

          if (transitionEl) {
            transitionEl.classList.add('content--transition')
          }
        }
      }
    },
    sectionCollector () {
      this.offsets = []

      for (let i = 0; i < this.blocks.length; i++) {
        let el = document.querySelector(`.${this.blocks[i]}`)

        if (el) {
          let elOffset = el.offsetTop
          this.offsets.push(elOffset)
        }
      }
    },
    bottomSlideClickHandler (id) {
      this.$refs.bottomSlider.slideTo(id)
    }
  },
  beforeMount () {
    this.$emit('themeClass', {
      themeClass: '--dark'
    })
    this.$emit('navActiveButton', {
      navActiveButton: 'projects'
    })
    this.$emit('headerBottomBorder', {
      headerBottomBorder: false
    })
  },
  mounted () {
    this.sectionCollector()

    window.addEventListener('scroll', () => {
      this.scrollContentTransition()
    })

    window.addEventListener('resize', () => {
      this.sectionCollector()
      this.scrollContentTransition()
    })
  },
  destroyed () {
    window.removeEventListener('scroll', () => {
      this.scrollContentTransition()
    })

    window.removeEventListener('resize', () => {
      this.sectionCollector()
      this.scrollContentTransition()
    })
  }
}
</script>
