<template>
  <footer class="footer" :class="this.footerClass">
      <div class="footer__collab">
        <div class="footer__collab-container">
          <div class="footer__collab-container_title h5">{{ $t('footer.follow') }}</div>

          <div class="footer__collab-container_block">
            <a href="https://www.instagram.com/skytop_engineering" target="_blank" class="footer__collab-container_item footer__collab-container_insta">
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.02344 0.9375H4.10156C1.83668 0.9375 0 2.9054 0 5.3321V10.6056C0 13.0323 1.83668 15.0002 4.10156 15.0002H9.02344C11.2883 15.0002 13.125 13.0323 13.125 10.6056V5.3321C13.125 2.9054 11.2883 0.9375 9.02344 0.9375ZM11.8945 10.6056C11.8945 12.3019 10.6066 13.6818 9.02344 13.6818H4.10156C2.51836 13.6818 1.23047 12.3019 1.23047 10.6056V5.3321C1.23047 3.63579 2.51836 2.25588 4.10156 2.25588H9.02344C10.6066 2.25588 11.8945 3.63579 11.8945 5.3321V10.6056Z" fill="#E7E7E7"/>
                <path d="M6.5625 4.45312C4.75043 4.45312 3.28125 6.02727 3.28125 7.96881C3.28125 9.91034 4.75043 11.4845 6.5625 11.4845C8.37457 11.4845 9.84375 9.91034 9.84375 7.96881C9.84375 6.02727 8.37457 4.45312 6.5625 4.45312ZM6.5625 10.1661C5.43211 10.1661 4.51172 9.17996 4.51172 7.96881C4.51172 6.75678 5.43211 5.77151 6.5625 5.77151C7.69289 5.77151 8.61328 6.75678 8.61328 7.96881C8.61328 9.17996 7.69289 10.1661 6.5625 10.1661Z" fill="#E7E7E7"/>
                <path d="M10.0901 4.65811C10.3315 4.65811 10.5273 4.44838 10.5273 4.18965C10.5273 3.93093 10.3315 3.72119 10.0901 3.72119C9.84859 3.72119 9.65283 3.93093 9.65283 4.18965C9.65283 4.44838 9.84859 4.65811 10.0901 4.65811Z" fill="#E7E7E7"/>
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/skytop-engineering" target="_blank" class="footer__collab-container_item footer__collab-container_linkedin">
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.81008 4.39453H0.875V14.0623H3.81008V4.39453Z" fill="#E7E7E7"/>
                <path d="M11.8048 4.50791C11.7737 4.49736 11.7441 4.48594 11.7113 4.47627C11.672 4.4666 11.6326 4.45869 11.5924 4.45166C11.4365 4.41826 11.2659 4.39453 11.0657 4.39453C9.35457 4.39453 8.2693 5.7278 7.91164 6.24283V4.39453H4.97656V14.0623H7.91164V8.78896C7.91164 8.78896 10.1298 5.47908 11.0657 7.91007C11.0657 10.08 11.0657 14.0623 11.0657 14.0623H14V7.5383C14 6.0776 13.0657 4.86034 11.8048 4.50791Z" fill="#E7E7E7"/>
                <path d="M2.31055 3.0761C3.10338 3.0761 3.74609 2.38749 3.74609 1.53805C3.74609 0.688608 3.10338 0 2.31055 0C1.51772 0 0.875 0.688608 0.875 1.53805C0.875 2.38749 1.51772 3.0761 2.31055 3.0761Z" fill="#E7E7E7"/>
              </svg>
            </a>
          </div>
        </div>

        <div class="footer__collab-container">
          <div class="footer__collab-container_title h5">{{ $t('footer.location') }}</div>

          <div class="footer__collab-container_block">
            <div class="footer__collab-container_text h5">{{ $t('footer.locationPlace') }}</div>
          </div>
        </div>

        <div class="footer__collab-container">
          <div class="footer__collab-container_title h5">{{ $t('footer.contact') }}</div>

          <div class="footer__collab-container_block">
            <a href="mailto:info@skytop.pro" class="footer__collab-container_text h5">{{ $t('footer.contactEmail') }}</a>
          </div>
        </div>

        <div class="footer__collab-contact"></div>
      </div>

      <div class="footer__logo-container">
        <div class="footer__logo">
          <svg width="160" height="45" viewBox="0 0 160 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.60032 37.3468C5.04551 37.0562 5.4907 37.0562 6.08429 37.0562H7.71667V35.8937H6.08429C5.6391 35.8937 5.04551 35.8937 4.60032 36.1843V33.8593H8.90383V32.6968H3.56152V40.253H9.349V39.0906H4.60032V37.3468Z" fill="#E8E8E8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.698 33.8592V40.3983H19.7368V35.167L24.1887 40.3983H25.2275V32.842H24.1887V38.5092L19.2916 32.842H17.8076L18.698 33.8592Z" fill="#E8E8E8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M41.4028 33.8593C40.6608 33.1327 39.6221 32.6968 38.4349 32.6968C36.2089 32.6968 34.2798 34.2952 34.2798 36.6202C34.2798 38.9452 36.0605 40.689 38.4349 40.689C39.6221 40.689 40.8093 40.1077 41.5512 39.3812V36.4749H39.0285L39.4737 37.4921H40.5124V38.7999C39.9189 39.2359 39.3253 39.5265 38.4349 39.5265C36.8025 39.5265 35.4669 38.364 35.4669 36.7656C35.4669 35.0218 36.8025 33.8593 38.4349 33.8593C39.3253 33.8593 40.0673 34.1499 40.6608 34.7312L41.4028 33.8593Z" fill="#E8E8E8"/>
            <path d="M51.7905 32.842H50.7517V40.3983H51.7905V32.842Z" fill="#E8E8E8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M61.7332 33.8592V40.3983H62.7719V35.167L67.0755 40.3983H68.2626V32.842H67.0755V38.5092L62.1783 32.842H60.8428L61.7332 33.8592Z" fill="#E8E8E8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M78.9472 37.3468C79.3924 37.0562 79.8376 37.0562 80.4312 37.0562H82.0636V35.8937H80.4312C79.986 35.8937 79.3924 35.8937 78.9472 36.1843V33.8593H83.2508V32.6968H77.9084V40.253H83.6959V39.0906H78.9472V37.3468Z" fill="#E8E8E8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M93.9352 37.3468C94.3804 37.0562 94.8256 37.0562 95.4192 37.0562H97.0516V35.8937H95.4192C94.974 35.8937 94.3804 35.8937 93.9352 36.1843V33.8593H98.2388V32.6968H92.8965V40.253H98.684V39.0906H93.9352V37.3468Z" fill="#E8E8E8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M108.775 34.0046H111.743C112.485 34.0046 113.079 34.2952 113.079 35.0218C113.079 35.8937 112.485 36.1843 111.743 36.1843H110.259C109.814 36.1843 109.22 36.1843 108.775 36.4749V34.0046ZM112.04 39.0906C112.633 40.2531 113.524 40.253 114.414 40.253V39.0906H114.117C114.117 39.0906 114.117 39.0906 113.969 39.0906C113.524 39.0906 113.375 38.9452 112.782 38.2187L112.188 37.0562C113.227 36.7655 113.969 36.039 113.969 34.8765C113.969 33.4233 112.93 32.6968 111.595 32.6968H107.588V40.253H108.775V37.4921C109.22 37.2015 109.665 37.2015 110.259 37.2015H111.149L112.04 39.0906Z" fill="#E8E8E8"/>
            <path d="M124.654 32.842H123.615V40.3983H124.654V32.842Z" fill="#E8E8E8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M134.596 33.8592V40.3983H135.635V35.167L140.087 40.3983H141.126V32.842H139.938V38.5092L135.041 32.842H133.706L134.596 33.8592Z" fill="#E8E8E8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M157.301 33.8593C156.559 33.1327 155.52 32.6968 154.333 32.6968C152.107 32.6968 150.178 34.2952 150.178 36.6202C150.178 38.9452 151.959 40.689 154.333 40.689C155.52 40.689 156.707 40.1077 157.449 39.3812V36.4749H154.927L155.372 37.4921H156.411V38.7999C155.817 39.2359 155.223 39.5265 154.333 39.5265C152.701 39.5265 151.365 38.364 151.365 36.7656C151.365 35.0218 152.701 33.8593 154.333 33.8593C155.223 33.8593 155.965 34.1499 156.559 34.7312L157.301 33.8593Z" fill="#E8E8E8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M134.893 0.873349H152.404C154.333 0.873349 155.817 1.45463 157.152 2.76244C158.488 4.07025 159.082 5.52334 159.082 7.26709V8.72021C159.082 10.464 158.488 12.0624 157.152 13.2249C155.817 14.5327 154.333 15.114 152.404 15.114H140.532L144.39 11.3359H152.404C152.701 11.3359 152.849 11.1906 153.146 10.7546C153.443 10.464 153.443 9.88272 153.443 9.44678V7.99365C153.443 7.4124 153.294 6.97647 153.146 6.68585C152.849 6.39522 152.701 6.10461 152.404 6.10461H140.532V27.9015L134.893 22.3796V5.95929H133.557L129.699 0.728027L134.893 0.873349Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M115.305 22.8154C112.188 22.8154 109.517 21.7982 107.291 19.6185C105.065 17.4389 104.026 14.8232 104.026 11.917C104.026 8.86543 105.065 6.39513 107.291 4.21545C109.517 2.03576 112.188 1.01855 115.305 1.01855H121.537C124.654 1.01855 127.325 2.03576 129.551 4.21545C131.777 6.39513 132.815 9.01074 132.815 11.917C132.815 14.9686 131.777 17.4389 129.551 19.6185C127.325 21.7982 124.654 22.8154 121.537 22.8154H115.305ZM115.305 6.24981C113.672 6.24981 112.337 6.83107 111.298 7.84826C110.111 9.01076 109.665 10.3186 109.665 11.917C109.665 13.5154 110.259 14.8232 111.298 15.9857C112.485 17.1482 113.821 17.5842 115.305 17.5842H121.537C123.021 17.5842 124.505 17.0029 125.544 15.9857C126.731 14.8232 127.176 13.5154 127.176 11.917C127.176 10.3186 126.583 9.01076 125.544 7.84826C124.505 6.68576 123.021 6.24981 121.537 6.24981H115.305Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M88.593 34.8764V6.24981H77.76L81.6183 1.01855H107.291C106.401 1.5998 105.51 2.18105 104.62 3.05292C103.581 4.07011 102.839 5.08731 102.097 6.24981H94.0837V29.3545L88.593 34.8764ZM113.821 5.37793V6.10449H111.743C112.337 5.81387 113.079 5.52324 113.821 5.37793Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M63.8109 12.4983L63.514 12.0624L54.165 0.873291H60.6945L66.6304 7.99359L72.5663 0.873291H79.0958L69.7467 12.0624L69.3016 12.4983V22.5248L63.8109 28.0467V12.4983Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M41.4028 11.917L52.681 22.8155H45.2611L33.9829 11.917L45.2611 0.873291H52.681L41.4028 11.917Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.0469 21.7983V22.5248L33.5376 28.0467V22.8155V20.7811V0.873291H28.0469V20.7811V21.7983Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4012 17.5842C18.698 17.5842 18.8464 17.4389 19.1432 17.0029C19.4399 16.7123 19.44 16.1311 19.44 15.6951C19.44 15.1139 19.2916 14.6779 19.1432 14.3873C18.8464 14.0967 18.698 13.806 18.4012 13.806H7.56819C5.63903 13.806 4.15504 13.2248 2.81946 11.917C1.48389 10.6092 0.890318 9.15605 0.890318 7.4123C0.890318 5.66855 1.48389 4.0701 2.81946 2.9076C4.15504 1.59979 5.63903 1.01855 7.56819 1.01855H25.5243L20.182 6.24981H7.56819C7.2714 6.24981 7.123 6.39512 6.82621 6.83105C6.52941 7.12168 6.52939 7.70292 6.52939 8.13886C6.52939 8.72011 6.67781 9.15604 6.82621 9.44666C7.123 9.73729 7.2714 10.0279 7.56819 10.0279H18.4012C20.3303 10.0279 21.8143 10.6092 23.1499 11.917C24.4855 13.2248 25.079 14.6779 25.079 16.4217C25.079 18.1654 24.4855 19.7639 23.1499 20.9264C21.8143 22.2342 20.3303 22.8154 18.4012 22.8154H5.93581L0.593506 17.5842H18.4012Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M99.2776 0.873291H106.994L103.136 6.24987H99.2776V0.873291Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M157.746 29.9357H95.4192V29.3545H157.746V29.9357ZM87.2574 29.9357H3.11621V29.3545H87.2574V29.9357Z" fill="#E8E8E8"/>
            <path d="M157.746 43.5952H3.11621V44.1765H157.746V43.5952Z" fill="#E8E8E8"/>
          </svg>
        </div>

        <div class="footer__logo_collab">
          <div class="footer__collab-container_title h5">Follow us</div>

          <div class="footer__collab-container_block footer__collab-container_mobile">
            <a href="https://www.instagram.com/skytop_engineering" target="_blank" class="footer__collab-container_item footer__collab-container_insta">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.02344 0.874756H4.10156C1.83668 0.874756 0 2.71147 0 4.97638V9.89834C0 12.1633 1.83668 14 4.10156 14H9.02344C11.2883 14 13.125 12.1633 13.125 9.89834V4.97638C13.125 2.71147 11.2883 0.874756 9.02344 0.874756ZM11.8945 9.89834C11.8945 11.4816 10.6066 12.7695 9.02344 12.7695H4.10156C2.51836 12.7695 1.23047 11.4816 1.23047 9.89834V4.97638C1.23047 3.39316 2.51836 2.10524 4.10156 2.10524H9.02344C10.6066 2.10524 11.8945 3.39316 11.8945 4.97638V9.89834Z" fill="#CECECE"/>
                <path d="M6.5625 4.15601C4.75043 4.15601 3.28125 5.62521 3.28125 7.43731C3.28125 9.24941 4.75043 10.7186 6.5625 10.7186C8.37457 10.7186 9.84375 9.24941 9.84375 7.43731C9.84375 5.62521 8.37457 4.15601 6.5625 4.15601ZM6.5625 9.48812C5.43211 9.48812 4.51172 8.56772 4.51172 7.43731C4.51172 6.30608 5.43211 5.38649 6.5625 5.38649C7.69289 5.38649 8.61328 6.30608 8.61328 7.43731C8.61328 8.56772 7.69289 9.48812 6.5625 9.48812Z" fill="#CECECE"/>
                <path d="M10.0899 4.34712C10.3314 4.34712 10.5271 4.15136 10.5271 3.90989C10.5271 3.66841 10.3314 3.47266 10.0899 3.47266C9.8484 3.47266 9.65265 3.66841 9.65265 3.90989C9.65265 4.15136 9.8484 4.34712 10.0899 4.34712Z" fill="#CECECE"/>
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/skytop-engineering" target="_blank" class="footer__collab-container_item footer__collab-container_linkedin">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.81008 4.10156H0.875V13.1248H3.81008V4.10156Z" fill="#CECECE"/>
                <path d="M11.8048 4.20738C11.7737 4.19754 11.7441 4.18687 11.7113 4.17785C11.672 4.16883 11.6326 4.16144 11.5924 4.15488C11.4365 4.12371 11.2659 4.10156 11.0657 4.10156C9.35457 4.10156 8.2693 5.34595 7.91164 5.82664V4.10156H4.97656V13.1248H7.91164V8.20303C7.91164 8.20303 10.1298 5.1138 11.0657 7.38274C11.0657 9.40804 11.0657 13.1248 11.0657 13.1248H14V7.03575C14 5.67242 13.0657 4.53632 11.8048 4.20738Z" fill="#CECECE"/>
                <path d="M2.31055 2.87103C3.10338 2.87103 3.74609 2.22832 3.74609 1.43551C3.74609 0.642701 3.10338 0 2.31055 0C1.51772 0 0.875 0.642701 0.875 1.43551C0.875 2.22832 1.51772 2.87103 2.31055 2.87103Z" fill="#CECECE"/>
              </svg>
            </a>
          </div>
        </div>
      </div>

    </footer>
</template>

<script>
export default {
  data () {
    return {
      footerClass: ''
    }
  },
  methods: {},
  beforeMount () {
    this.footerClass = `footer${this.$parent.$data.themeClass}`
  }

}
</script>
