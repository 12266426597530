<template>
  <div class="contact-page">
    <div class="contact-page__container">
      <div class="contact-page__wrapper content--transition">
        <div class="contact-page__content">
          <div class="contact-page__content_container">
            <div class="contact-page__content_item">
              <div class="contact-page__content_item-title h3">
                {{ $t('contact.inquiriesTitle') }}
              </div>
              <div class="contact-page__content_item-text h4">
                <a href="mailto:info@skytop.pro" class="contact-page__content_item-text">info@skytop.pro</a>
              </div>
              <div class="contact-page__content_item-text h4">
                <a href="tel:+971561944887" class="contact-page__content_item-text">+971 56 194 48 87</a>
              </div>
            </div>

            <div class="contact-page__content_item">
              <div class="contact-page__content_item-title h3">
                {{ $t('contact.careersTitle') }}
              </div>
              <div class="contact-page__content_item-text h4">
                  <a href="mailto:hr@skytop.pro" class="contact-page__content_item-text">hr@skytop.pro</a>
              </div>
            </div>
          </div>

          <div class="contact-page__content_container">
            <div class="contact-page__content_item">
              <div class="contact-page__content_item-title h3">
                {{ $t('contact.locationPlace') }}
              </div>
              <div class="contact-page__content_item-text h4">
                {{ $t('contact.locationAddress') }}
              </div>
              <div class="contact-page__content_item-text h4">
                {{ $t('contact.locationAddress1') }}
              </div>
            </div>

            <div class="contact-page__content_item">
              <div class="contact-page__content_item-title h3">
                {{ $t('contact.followTitle') }}
              </div>
              <div class="contact-page__content_collab">
                <a href="https://www.instagram.com/skytop_engineering" target="_blank" class="contact-page__content_collab-link">
                  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.6016 0.140381H5.27344C2.36145 0.140381 0 2.53434 0 5.48643V11.9017C0 14.8538 2.36145 17.2477 5.27344 17.2477H11.6016C14.5136 17.2477 16.875 14.8538 16.875 11.9017V5.48643C16.875 2.53434 14.5136 0.140381 11.6016 0.140381ZM15.293 11.9017C15.293 13.9653 13.6371 15.6439 11.6016 15.6439H5.27344C3.23789 15.6439 1.58203 13.9653 1.58203 11.9017V5.48643C1.58203 3.42286 3.23789 1.7442 5.27344 1.7442H11.6016C13.6371 1.7442 15.293 3.42286 15.293 5.48643V11.9017Z" fill="#636363"/>
                    <path d="M8.4375 4.41724C6.1077 4.41724 4.21875 6.33219 4.21875 8.69408C4.21875 11.056 6.1077 12.9709 8.4375 12.9709C10.7673 12.9709 12.6562 11.056 12.6562 8.69408C12.6562 6.33219 10.7673 4.41724 8.4375 4.41724ZM8.4375 11.3671C6.98414 11.3671 5.80078 10.1674 5.80078 8.69408C5.80078 7.21964 6.98414 6.02105 8.4375 6.02105C9.89086 6.02105 11.0742 7.21964 11.0742 8.69408C11.0742 10.1674 9.89086 11.3671 8.4375 11.3671Z" fill="#636363"/>
                    <path d="M12.9733 4.66682C13.2837 4.66682 13.5354 4.41158 13.5354 4.09672C13.5354 3.78186 13.2837 3.52661 12.9733 3.52661C12.6628 3.52661 12.4111 3.78186 12.4111 4.09672C12.4111 4.41158 12.6628 4.66682 12.9733 4.66682Z" fill="#636363"/>
                  </svg>
                </a>
                <a href="https://www.linkedin.com/company/skytop-engineering" target="_blank" class="contact-page__content_collab-link">
                  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.89867 5.34583H0.125V17.1068H3.89867V5.34583Z" fill="#636363"/>
                    <path d="M14.1777 5.48375C14.1376 5.47092 14.0996 5.45702 14.0574 5.44526C14.0068 5.4335 13.9562 5.42388 13.9045 5.41532C13.7041 5.37469 13.4847 5.34583 13.2274 5.34583C11.0273 5.34583 9.63195 6.96776 9.17211 7.5943V5.34583H5.39844V17.1068H9.17211V10.6917C9.17211 10.6917 12.024 6.66519 13.2274 9.62253C13.2274 12.2623 13.2274 17.1068 13.2274 17.1068H17V9.17027C17 7.3933 15.7987 5.91249 14.1777 5.48375Z" fill="#636363"/>
                    <path d="M1.9707 3.74212C2.99006 3.74212 3.81641 2.90441 3.81641 1.87106C3.81641 0.837701 2.99006 0 1.9707 0C0.951349 0 0.125 0.837701 0.125 1.87106C0.125 2.90441 0.951349 3.74212 1.9707 3.74212Z" fill="#636363"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <!-- <div class="contact-page__content_container">
            <div class="contact-page__content_crypto-wrapper">
              <div class="contact-page__content_crypto-icons">
                <img
                  class="contact-page__content_crypto-icon"
                  src="../assets/img/crypto/etherium.png"
                />

                <img
                  class="contact-page__content_crypto-icon"
                  src="../assets/img/crypto/bitcoin.png"
                />

                <img
                  class="contact-page__content_crypto-icon"
                  src="../assets/img/crypto/tether.png"
                />
              </div>

              <div class="contact-page__content_crypto-description">
                <span class="contact-page__content_crypto-text">
                  {{ $t('contact.cryptoLine1') }}
                </span>

                <br />

                <span class="contact-page__content_crypto-text">
                  {{ $t('contact.cryptoLine2') }}
                </span>
              </div>
            </div>
          </div> -->
        </div>
        <div id="map" class="contact-page__map">
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer'
import GoogleMapsLoader from 'google-maps'

export default {
  components: {
    Footer
  },
  data () {
    return {
      themeClass: '--dark',
      name: 'map'
    }
  },
  computed: {
    mapMarkers: function () {
      return this.markers
    }
  },
  methods: {},
  beforeMount () {
    this.$emit('themeClass', {
      themeClass: '--dark'
    })
    this.$emit('navActiveButton', {
      navActiveButton: 'contact'
    })
    this.$emit('headerBottomBorder', {
      headerBottomBorder: false
    })
  },
  mounted () {
    /* eslint-disable */
    GoogleMapsLoader.KEY = 'AIzaSyBxEaFToLpUrNs002VeXcC18u4c6UYz02w';
    GoogleMapsLoader.load(function(google) {
      let options = {
        zoom: 5,
        center:{ lat: 25.1013931, lng: 55.1738517 }
      }

      var marker = new google.maps.Marker({
        position: { lat: 25.1013931, lng: 55.1738517 },
        map: map,
        label: 'S',
        title: 'SkyTop'
      });

      let map = new google.maps.Map(document.getElementById('map'), options);
      marker.setMap(map);
    });
    /* eslint-enable */
  },
  destroyed () {}
}
</script>
