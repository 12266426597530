<template>
  <div class="services-page">

    <div class="page__trans-1 content--transition">
      <div class="universal__title">
        <div class="universal__title_back h1">{{ $t('services.servicesBack') }}</div>
        <div class="universal__title_main">
          <div class="universal__title_main-text h3">{{ $t('services.servicesTitle') }}</div>
          <div class="universal__title_main-subtext h3">{{ $t('services.servicesSubTitle') }}</div>
        </div>
      </div>

      <div class="universal__content">
        <div class="universal__content_col">
          <p class="h4">
            {{ $t('services.servicesText') }}
          </p>
          <p class="h4">
            {{ $t('services.servicesText2') }}
          </p>
        </div>
      </div>
      <div class="universal__line">
        <div class="universal__line_stripe"></div>
      </div>

      <div class="for">
        <div class="for__title h4">
          {{ $t('services.servicesFor') }}
        </div>
        <div class="for__list">
          <div class="for__list_col">
            <div class="for__list_item">
              <div class="for__list_num h4">01</div>
              <div class="for__list_text h4">
                {{ $t('services.servicesForItem1') }}
              </div>
            </div>
            <div class="for__list_item">
              <div class="for__list_num h4">02</div>
              <div class="for__list_text h4">
                {{ $t('services.servicesForItem2') }}
              </div>
            </div>
          </div>
          <div class="for__list_col">
            <div class="for__list_item">
              <div class="for__list_num h4">03</div>
              <div class="for__list_text h4">
                {{ $t('services.servicesForItem3') }}
              </div>
            </div>
            <div class="for__list_item">
              <div class="for__list_num h4">04</div>
              <div class="for__list_text h4">
                {{ $t('services.servicesForItem4') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page__trans-2">
      <section id="" class="text-img">
        <div class="text-img__content">
          <div class="text-img__content_subtitle h3">
            {{ $t('services.servicesSecTitle1') }}
          </div>

          <div class="text-img__content_list">
            <div class="text-img__content_col">
              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-1-Item1') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-1-Item2') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-1-Item3') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-1-Item4') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-1-Item5') }}
                </div>
              </div>
            </div>

            <div class="text-img__content_col">
              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item1') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item2') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item3') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item4') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item5') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item6') }}
                </div>
              </div>
            </div>

            <div class="text-img__content_col">
              <div class="text-img__content_item" style="display: none;">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item6') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item1') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item2') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item3') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item4') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item5') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item6') }}
                </div>
              </div>
            </div>

            <div class="text-img__content_col">
              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-1-Item1') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-1-Item2') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-1-Item3') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-1-Item4') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-1-Item5') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item1') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item2') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item3') }}
                </div>
              </div>
            </div>

            <div class="text-img__content_col">
              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item4') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item5') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-2-Item6') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item1') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item2') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item3') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item4') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item5') }}
                </div>
              </div>

              <div class="text-img__content_item">
                <div class="text-img__content_item-icon">
                  <div class="text-img__content_item-circle"></div>
                </div>
                <div class="text-img__content_item-text h4">
                  {{ $t('services.servicesList-3-Item6') }}
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="text-img__img">
          <img src="../assets/img/services/services-1.png" />
        </div>
      </section>
    </div>

    <div class="page__trans-3">
      <section class="img-text">
        <div class="img-text__img">
          <img src="../assets/img/services/services-2.png" />
        </div>
        <div class="img-text__content">
          <div class="img-text__content_subtitle h3">
            {{ $t('services.servicesSecTitle2') }}
          </div>

          <div class="img-text__content_list">
            <div class="img-text__content_col">
              <div class="img-text__content_item">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-1-Item1') }}
                </div>
              </div>

              <div class="img-text__content_item">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-1-Item2') }}
                </div>
              </div>

              <div class="img-text__content_item">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-1-Item3') }}
                </div>
              </div>

              <div class="img-text__content_item">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-1-Item4') }}
                </div>
              </div>

              <div class="img-text__content_item">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-1-Item5') }}
                </div>
              </div>

              <div class="img-text__content_item" style="display: none;">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-2-Item1') }}
                </div>
              </div>
            </div>

            <div class="img-text__content_col">
              <div class="img-text__content_item">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-2-Item1') }}
                </div>
              </div>

              <div class="img-text__content_item" style="display: none;">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-1-Item5') }}
                </div>
              </div>

              <div class="img-text__content_item">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-2-Item2') }}
                </div>
              </div>

              <div class="img-text__content_item">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-2-Item3') }}
                </div>
              </div>

              <div class="img-text__content_item">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-2-Item4') }}
                </div>
              </div>

              <div class="img-text__content_item">
                <div class="img-text__content_item-icon">
                  <div class="img-text__content_item-circle"></div>
                </div>
                <div class="img-text__content_item-text h4">
                  {{ $t('services.servicesCol-2-Item5') }}
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer'

export default {
  components: {
    Footer
  },
  data () {
    return {
      themeClass: '--dark',
      blocks: [
        // 'page__trans-1',
        'page__trans-2',
        'page__trans-3'
      ],
      offsets: [],
      windowHeight: 0
    }
  },
  computed: {},
  methods: {
    windowHeightCounter () {
      this.windowHeight = window.innerHeight
    },
    scrollContentTransition (event) {
      let winScrollY = this.$parent.$data.topOffset + 0.65 * window.innerHeight
      let transitionEl

      for (let i = 0; i < this.offsets.length; i++) {
        if (winScrollY > this.offsets[i]) {
          transitionEl = document.querySelector(`.${this.blocks[i]}`)

          if (transitionEl) {
            transitionEl.classList.add('content--transition')
          }
        }
      }
    },
    sectionCollector () {
      this.offsets = []

      for (let i = 0; i < this.blocks.length; i++) {
        let el = document.querySelector(`.${this.blocks[i]}`)

        if (el) {
          let elOffset = el.offsetTop
          this.offsets.push(elOffset)
        }
      }
    }
  },
  beforeMount () {
    this.$emit('themeClass', {
      themeClass: '--dark'
    })
    this.$emit('navActiveButton', {
      navActiveButton: 'services'
    })
    this.$emit('headerBottomBorder', {
      headerBottomBorder: false
    })
  },
  mounted () {
    this.sectionCollector()

    window.addEventListener('scroll', () => {
      this.scrollContentTransition()
    })

    window.addEventListener('resize', () => {
      this.sectionCollector()
      this.scrollContentTransition()
    })
  },
  destroyed () {
    window.removeEventListener('scroll', () => {
      this.scrollContentTransition()
    })

    window.removeEventListener('resize', () => {
      this.sectionCollector()
      this.scrollContentTransition()
    })
  }
}
</script>
