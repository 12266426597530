<template>
  <div class="project-page" id="top-anchor">
    <div class="project-page__container">
      <div class="page__transit-1 content--transition">
        <div class="project-page__breadcrumbs">
          <router-link to="/projects" class="project-page__breadcrumbs_link">Projects</router-link>
          > <span class="project-page__breadcrumbs_breadcrumb">{{ $t('projects.pr19Title') }}</span>
        </div>

        <div class="project">
          <div class="project__header">
            <p class="project__title">{{ $t('projects.pr19Title') }}</p>
            <p class="project__description">{{ $t('projects.pr19Description') }}</p>
          </div>

          <div class="universal__line">
            <div class="universal__line_stripe"></div>
          </div>

          <div class="project__content">
            <div class="project__col">
              <div class="project__col_title h5">{{ $t('projects.aboutProject') }}</div>
            </div>

            <div class="project__col">
              <div class="project__col_list">
                <div class="project__col_list-col">
                  <div class="project__col_list-item">
                    <div class="project__col_list-text h5">
                      <span>{{ $t('projects.pr19Location') }}</span>
                      {{ $t('projects.pr19LocationText') }}
                    </div>
                  </div>

                  <div class="project__col_list-item">
                    <div class="project__col_list-text h5">
                      <span>{{ $t('projects.pr19Scope') }}</span>
                      {{ $t('projects.pr19ScopeText') }}
                    </div>
                  </div>

                  <div class="project__col_list-item">
                    <div class="project__col_list-text h5">
                      <span>{{ $t('projects.pr19Area') }}</span>
                      {{ $t('projects.pr19AreaText') }}
                      <sup>2</sup>
                    </div>
                  </div>
                </div>

                <div class="project__col_list-col">
                  <div class="project__col_list-item">
                    <div class="project__col_list-text h5">
                      <span>{{ $t('projects.pr19Features') }}</span>
                      <br>
                      <p class="project__col_list-text-feature">
                        {{ $t('projects.pr19FeaturesText') }}
                      </p>
                      <p class="project__col_list-text-feature">
                        {{ $t('projects.pr19FeaturesText2') }}
                      </p>
                      <p class="project__col_list-text-feature">
                        {{ $t('projects.pr19FeaturesText3') }}
                      </p>
                      <p class="project__col_list-text-feature">
                        {{ $t('projects.pr19FeaturesText4') }}
                      </p>
                      <p class="project__col_list-text-feature">
                        {{ $t('projects.pr19FeaturesText5') }}
                      </p>
                    </div>
                  </div>

                  <div class="project__col_list-item">
                    <div class="project__col_list-text h5">
                      <span>{{ $t('projects.pr19Function') }}</span>
                      {{ $t('projects.pr19FunctionText') }}
                    </div>
                  </div>

                  <div class="project__col_list-item">
                    <div class="project__col_list-text h5">
                      <span>{{ $t('projects.pr19Status') }}</span>
                      {{ $t('projects.pr19StatusText') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="project__images page__transit-2 project__images--desktop">
            <div class="project__images_img-container" v-for="item in project19" :key="item.imgsrc">
              <div
                class="project__images_img-item"
                :style="{ backgroundImage: 'url(' + require('../../assets/img/projects/project-19/pr-' + item.imgsrc + '.png') + ')' }"
              ></div>
            </div>

            <div class="scrolltop__wrapper">
              <a href="#top-anchor" class="scrolltop__anchor_icon" v-smooth-scroll="{ duration: 1000, offset: +20 }" >
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="1">
                  <path d="M30 52.5C42.4264 52.5 52.5 42.4264 52.5 30C52.5 17.5736 42.4264 7.5 30 7.5C17.5736 7.5 7.5 17.5736 7.5 30C7.5 42.4264 17.5736 52.5 30 52.5Z" stroke="#2B3854" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M22.0449 28.5799L29.9998 20.625L37.9548 28.5799" stroke="#2B3854" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M30 39.375V20.625" stroke="#2B3854" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                </svg>
              </a>
            </div>
          </div>

          <div class="project__images page__transit-3 project__images--mobile">
            <div class="project__images_img-container" v-for="item in project19" :key="item.imgsrc">
              <div
                class="project__images_img-item"
                :style="{ backgroundImage: 'url(' + require('../../assets/img/projects/project-19-2/pr-' + item.imgsrc + '.png') + ')' }"
              ></div>
            </div>

            <div class="scrolltop__wrapper">
              <a href="#top-anchor" class="scrolltop__anchor_icon" v-smooth-scroll="{ duration: 1000, offset: +20 }" >
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="1">
                  <path d="M30 52.5C42.4264 52.5 52.5 42.4264 52.5 30C52.5 17.5736 42.4264 7.5 30 7.5C17.5736 7.5 7.5 17.5736 7.5 30C7.5 42.4264 17.5736 52.5 30 52.5Z" stroke="#2B3854" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M22.0449 28.5799L29.9998 20.625L37.9548 28.5799" stroke="#2B3854" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M30 39.375V20.625" stroke="#2B3854" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../../components/Footer'

export default {
  components: {
    Footer
  },
  data () {
    return {
      themeClass: '--dark',
      blocks: [
        'page__transit-2',
        'page__transit-3'
      ],
      offsets: [],
      windowHeight: 0,
      project19: [
        {
          imgsrc: '1'
        },
        {
          imgsrc: '2'
        },
        {
          imgsrc: '3'
        },
        {
          imgsrc: '4'
        }
      ]
    }
  },
  methods: {
    windowHeightCounter () {
      this.windowHeight = window.innerHeight
    },
    scrollContentTransition (event) {
      let winScrollY = this.$parent.$data.topOffset + 0.65 * window.innerHeight
      let transitionEl

      for (let i = 0; i < this.offsets.length; i++) {
        if (winScrollY > this.offsets[i]) {
          transitionEl = document.querySelector(`.${this.blocks[i]}`)

          if (transitionEl) {
            transitionEl.classList.add('content--transition')
          }
        }
      }
    },
    sectionCollector () {
      this.offsets = []

      for (let i = 0; i < this.blocks.length; i++) {
        let el = document.querySelector(`.${this.blocks[i]}`)

        if (el) {
          let elOffset = el.offsetTop
          this.offsets.push(elOffset)
        }
      }
    }
  },
  beforeMount () {
    this.$emit('themeClass', {
      themeClass: '--dark'
    })
    this.$emit('navActiveButton', {
      navActiveButton: 'projects'
    })
    this.$emit('headerBottomBorder', {
      headerBottomBorder: false
    })
  },
  mounted () {
    this.sectionCollector()

    window.addEventListener('scroll', () => {
      this.scrollContentTransition()
    })

    window.addEventListener('resize', () => {
      this.sectionCollector()
      this.scrollContentTransition()
    })
  },
  destroyed () {
    window.removeEventListener('scroll', () => {
      this.scrollContentTransition()
    })

    window.removeEventListener('resize', () => {
      this.sectionCollector()
      this.scrollContentTransition()
    })
  }
}
</script>
