<template>
  <div id="app" :class="this.$i18n.locale">
    <header
      class="header"
      :class="[{'header--color': this.changeStatus}, this.headerClass, {'header--display' : !this.headerDisplay}, {'header--border' : this.headerBottomBorder}, {'header--color-fix': this.changeStatusFix} ]">
      <router-link to="/" class="header__logo">
        <svg
          width="160"
          height="45"
          viewBox="0 0 160 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.60032 37.3469C5.04551 37.0562 5.4907 37.0562 6.08429 37.0562H7.71667V35.8937H6.08429C5.6391 35.8937 5.04551 35.8937 4.60032 36.1844V33.8594H8.90383V32.6968H3.56152V40.2531H9.349V39.0906H4.60032V37.3469Z"
            fill="#E8E8E8"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.6985 33.8594V40.3984H19.7373V35.1672L24.1892 40.3984H25.228V32.8422H24.1892V38.5093L19.2921 32.8422H17.8081L18.6985 33.8594Z"
            fill="#E8E8E8"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M41.4028 33.8594C40.6608 33.1328 39.6221 32.6968 38.4349 32.6968C36.2089 32.6968 34.2798 34.2953 34.2798 36.6203C34.2798 38.9453 36.0605 40.689 38.4349 40.689C39.6221 40.689 40.8093 40.1078 41.5512 39.3812V36.475H39.0285L39.4737 37.4922H40.5124V38.8C39.9189 39.2359 39.3253 39.5265 38.4349 39.5265C36.8025 39.5265 35.4669 38.3641 35.4669 36.7656C35.4669 35.0219 36.8025 33.8594 38.4349 33.8594C39.3253 33.8594 40.0673 34.15 40.6608 34.7312L41.4028 33.8594Z"
            fill="#E8E8E8"
          />
          <path d="M51.7907 32.8422H50.752V40.3984H51.7907V32.8422Z" fill="#E8E8E8" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M61.7337 33.8594V40.3984H62.7724V35.1672L67.076 40.3984H68.2631V32.8422H67.076V38.5093L62.1788 32.8422H60.8433L61.7337 33.8594Z"
            fill="#E8E8E8"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M78.9475 37.3469C79.3927 37.0562 79.8379 37.0562 80.4315 37.0562H82.0638V35.8937H80.4315C79.9863 35.8937 79.3927 35.8937 78.9475 36.1844V33.8594H83.251V32.6968H77.9087V40.2531H83.6962V39.0906H78.9475V37.3469Z"
            fill="#E8E8E8"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M93.9357 37.3469C94.3809 37.0562 94.8261 37.0562 95.4197 37.0562H97.0521V35.8937H95.4197C94.9745 35.8937 94.3809 35.8937 93.9357 36.1844V33.8594H98.2393V32.6968H92.897V40.2531H98.6844V39.0906H93.9357V37.3469Z"
            fill="#E8E8E8"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M108.776 34.0047H111.743C112.485 34.0047 113.079 34.2953 113.079 35.0218C113.079 35.8937 112.485 36.1844 111.743 36.1844H110.26C109.814 36.1844 109.221 36.1843 108.776 36.475V34.0047ZM112.04 39.0906C112.634 40.2531 113.524 40.2531 114.415 40.2531V39.0906H114.118C114.118 39.0906 114.118 39.0906 113.969 39.0906C113.524 39.0906 113.376 38.9453 112.782 38.2187L112.189 37.0562C113.227 36.7656 113.969 36.039 113.969 34.8765C113.969 33.4234 112.931 32.6968 111.595 32.6968H107.588V40.2531H108.776V37.4922C109.221 37.2016 109.666 37.2015 110.26 37.2015H111.15L112.04 39.0906Z"
            fill="#E8E8E8"
          />
          <path d="M124.654 32.8422H123.615V40.3984H124.654V32.8422Z" fill="#E8E8E8" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M134.596 33.8594V40.3984H135.635V35.1672L140.087 40.3984H141.126V32.8422H139.939V38.5093L135.042 32.8422H133.706L134.596 33.8594Z"
            fill="#E8E8E8"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M157.301 33.8594C156.559 33.1328 155.521 32.6968 154.333 32.6968C152.107 32.6968 150.178 34.2953 150.178 36.6203C150.178 38.9453 151.959 40.689 154.333 40.689C155.521 40.689 156.708 40.1078 157.45 39.3812V36.475H154.927L155.372 37.4922H156.411V38.8C155.817 39.2359 155.224 39.5265 154.333 39.5265C152.701 39.5265 151.365 38.3641 151.365 36.7656C151.365 35.0219 152.701 33.8594 154.333 33.8594C155.224 33.8594 155.966 34.15 156.559 34.7312L157.301 33.8594Z"
            fill="#E8E8E8"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M134.893 0.87341H152.404C154.333 0.87341 155.817 1.45469 157.153 2.7625C158.488 4.07031 159.082 5.5234 159.082 7.26715V8.72028C159.082 10.464 158.488 12.0625 157.153 13.225C155.817 14.5328 154.333 15.1141 152.404 15.1141H140.532L144.391 11.3359H152.404C152.701 11.3359 152.849 11.1906 153.146 10.7547C153.443 10.4641 153.443 9.88278 153.443 9.44684V7.99371C153.443 7.41246 153.294 6.97653 153.146 6.68591C152.849 6.39528 152.701 6.10467 152.404 6.10467H140.532V27.9015L134.893 22.3797V5.95935H133.558L129.699 0.728088L134.893 0.87341Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M115.305 22.8156C112.188 22.8156 109.517 21.7984 107.291 19.6187C105.065 17.439 104.026 14.8234 104.026 11.9172C104.026 8.86561 105.065 6.39532 107.291 4.21563C109.517 2.03594 112.188 1.01874 115.305 1.01874H121.537C124.654 1.01874 127.325 2.03594 129.551 4.21563C131.777 6.39532 132.815 9.01093 132.815 11.9172C132.815 14.9687 131.777 17.439 129.551 19.6187C127.325 21.7984 124.654 22.8156 121.537 22.8156H115.305ZM115.305 6.25C113.672 6.25 112.337 6.83126 111.298 7.84844C110.111 9.01094 109.665 10.3187 109.665 11.9172C109.665 13.5156 110.259 14.8234 111.298 15.9859C112.485 17.1484 113.821 17.5844 115.305 17.5844H121.537C123.021 17.5844 124.505 17.0031 125.544 15.9859C126.731 14.8234 127.176 13.5156 127.176 11.9172C127.176 10.3187 126.583 9.01094 125.544 7.84844C124.505 6.68594 123.021 6.25 121.537 6.25H115.305Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M88.5933 34.8765V6.25H77.7603L81.6186 1.01874H107.291C106.401 1.59999 105.511 2.18123 104.62 3.0531C103.581 4.07029 102.839 5.0875 102.097 6.25H94.0839V29.3547L88.5933 34.8765ZM113.821 5.37811V6.10468H111.743C112.337 5.81405 113.079 5.52343 113.821 5.37811Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M63.8109 12.4984L63.514 12.0625L54.165 0.873413H60.6945L66.6304 7.99372L72.5663 0.873413H79.0958L69.7467 12.0625L69.3016 12.4984V22.525L63.8109 28.0469V12.4984Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M41.4028 11.9172L52.681 22.8156H45.2611L33.9829 11.9172L45.2611 0.873413H52.681L41.4028 11.9172Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.0474 21.7984V22.525L33.5381 28.0469V22.8156V20.7812V0.873413H28.0474V20.7812V21.7984Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.4014 17.5844C18.6982 17.5844 18.8466 17.4391 19.1434 17.0031C19.4402 16.7125 19.4402 16.1312 19.4402 15.6953C19.4402 15.1141 19.2918 14.6781 19.1434 14.3875C18.8466 14.0969 18.6982 13.8062 18.4014 13.8062H7.56844C5.63927 13.8062 4.15528 13.225 2.81971 11.9172C1.48413 10.6094 0.890562 9.15623 0.890562 7.41248C0.890562 5.66873 1.48413 4.07028 2.81971 2.90778C4.15528 1.59997 5.63927 1.01874 7.56844 1.01874H25.5245L20.1822 6.25H7.56844C7.27164 6.25 7.12324 6.3953 6.82645 6.83124C6.52966 7.12186 6.52964 7.7031 6.52964 8.13904C6.52964 8.72029 6.67805 9.15622 6.82645 9.44685C7.12324 9.73747 7.27164 10.0281 7.56844 10.0281H18.4014C20.3306 10.0281 21.8146 10.6094 23.1501 11.9172C24.4857 13.225 25.0793 14.6781 25.0793 16.4219C25.0793 18.1656 24.4857 19.7641 23.1501 20.9266C21.8146 22.2344 20.3306 22.8156 18.4014 22.8156H5.93606L0.59375 17.5844H18.4014Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M99.2778 0.873413H106.995L103.136 6.24999H99.2778V0.873413Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M157.747 29.9359H95.4197V29.3547H157.747V29.9359ZM87.2579 29.9359H3.1167V29.3547H87.2579V29.9359Z"
            fill="#E8E8E8"
          />
          <path d="M157.747 43.5953H3.1167V44.1766H157.747V43.5953Z" fill="#E8E8E8" />
        </svg>
      </router-link>
      <div class="header__nav">
        <div class="header__nav_link h5" :class="{'header__nav_link--active' : this.navActiveButton === 'about'}" @click="directLink('about-us')">{{ $t('header.navAbout') }}</div>
        <div class="header__nav_link h5" :class="{'header__nav_link--active' : this.navActiveButton === 'services'}" @click="directLink('services')">{{ $t('header.navServices') }}</div>
        <div class="header__nav_link h5" :class="{'header__nav_link--active' : this.navActiveButton === 'projects'}" @click="directLink('projects')">{{ $t('header.navProjects') }}</div>
        <div class="header__nav_link h5" :class="{'header__nav_link--active' : this.navActiveButton === 'contact'}" @click="directLink('contact')">{{ $t('header.navContact') }}</div>
      </div>
      <div class="header__language">
        <!--<a href="#" @click="setLocale('ru')" class="header__language_item h5" :class="{ 'header__language_item--active' : this.$i18n.locale == 'ru' }">ru</a>
        <a href="#" @click="setLocale('en')" class="header__language_item h5" :class="{ 'header__language_item--active' : this.$i18n.locale == 'en' }">en</a>-->
      </div>
      <div class="header__burger">
        <input id="header__burger_toggle" class="header__burger_toggle" type="checkbox" />
        <label class="header__burger_btn" :class="{'header__burger_btn--active' : this.menuActive}" @click="toggleMenu()" for="header__burger_toggle">
          <span></span>
        </label>

        <div class="header__burger_bg" :class="{'header__burger_bg--active' : this.menuActive}"></div>

        <div class="header__burger_box">
          <div class="header__burger-nav">
            <div class="header__burger-nav_container">
              <div :class="{'header__burger-nav_link--active' : this.navActiveButton == 'about'}" @click="directLink('about-us')" to="/about-us" class="header__burger-nav_link h5">{{ $t('header.navAbout') }}</div><br>
              <div :class="{'header__burger-nav_link--active' : this.navActiveButton == 'services'}" @click="directLink('services')" to="/about-us" class="header__burger-nav_link h5">{{ $t('header.navServices') }}</div><br>
              <div :class="{'header__burger-nav_link--active' : this.navActiveButton == 'projects'}" @click="directLink('projects')" to="/about-us" class="header__burger-nav_link h5">{{ $t('header.navProjects') }}</div><br>
              <div :class="{'header__burger-nav_link--active' : this.navActiveButton == 'contact'}" @click="directLink('contact')" to="/about-us" class="header__burger-nav_link h5">{{ $t('header.navContact') }}</div><br>
            </div>
          </div>
          <div class="header__burger-info">
            <div class="header__burger-info_collab">
              <div class="header__burger-info_collab-item">
                <div class="header__burger-info_collab-title h4">{{ $t('footer.location') }}</div>
                <div class="header__burger-info_collab-text h4">{{ $t('footer.locationPlace') }}</div>
              </div>

              <div class="header__burger-info_collab-item">
                <div class="header__burger-info_collab-title h4">{{ $t('footer.contact') }}</div>
                <a href="mailto:name@email.com" class="header__burger-info_collab-text h4">{{ $t('footer.contactEmail') }}</a>
              </div>

              <div class="header__burger-info_collab-item">
                <div class="header__burger-info_collab-title">{{ $t('footer.follow') }}</div>
                <div class="header__burger-info_collab-block">
                  <a href="https://www.instagram.com/skytop_engineering" target="_blank" class="header__burger-info_collab-block-item">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.02344 0.874786H4.10156C1.83668 0.874786 0 2.7115 0 4.97642V9.89837C0 12.1633 1.83668 14 4.10156 14H9.02344C11.2883 14 13.125 12.1633 13.125 9.89837V4.97642C13.125 2.7115 11.2883 0.874786 9.02344 0.874786ZM11.8945 9.89837C11.8945 11.4816 10.6066 12.7695 9.02344 12.7695H4.10156C2.51836 12.7695 1.23047 11.4816 1.23047 9.89837V4.97642C1.23047 3.39319 2.51836 2.10528 4.10156 2.10528H9.02344C10.6066 2.10528 11.8945 3.39319 11.8945 4.97642V9.89837Z" fill="#535353"/>
                      <path d="M6.5625 4.15608C4.75043 4.15608 3.28125 5.62529 3.28125 7.43739C3.28125 9.24948 4.75043 10.7187 6.5625 10.7187C8.37457 10.7187 9.84375 9.24948 9.84375 7.43739C9.84375 5.62529 8.37457 4.15608 6.5625 4.15608ZM6.5625 9.4882C5.43211 9.4882 4.51172 8.56779 4.51172 7.43739C4.51172 6.30616 5.43211 5.38657 6.5625 5.38657C7.69289 5.38657 8.61328 6.30616 8.61328 7.43739C8.61328 8.56779 7.69289 9.4882 6.5625 9.4882Z" fill="#535353"/>
                      <path d="M10.0899 4.34721C10.3314 4.34721 10.5271 4.15145 10.5271 3.90998C10.5271 3.6685 10.3314 3.47275 10.0899 3.47275C9.8484 3.47275 9.65265 3.6685 9.65265 3.90998C9.65265 4.15145 9.8484 4.34721 10.0899 4.34721Z" fill="#535353"/>
                    </svg>
                  </a>
                  <a href="https://www.linkedin.com/company/skytop-engineering" target="_blank" class="header__burger-info_collab-block-item">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.81008 4.10146H0.875V13.1247H3.81008V4.10146Z" fill="#535353"/>
                      <path d="M11.8048 4.20727C11.7737 4.19743 11.7441 4.18677 11.7113 4.17774C11.672 4.16872 11.6326 4.16134 11.5924 4.15477C11.4365 4.1236 11.2659 4.10146 11.0657 4.10146C9.35457 4.10146 8.2693 5.34584 7.91164 5.82653V4.10146H4.97656V13.1247H7.91164V8.20292C7.91164 8.20292 10.1298 5.1137 11.0657 7.38263C11.0657 9.40793 11.0657 13.1247 11.0657 13.1247H14V7.03564C14 5.67232 13.0657 4.53621 11.8048 4.20727Z" fill="#535353"/>
                      <path d="M2.31055 2.87103C3.10338 2.87103 3.74609 2.22832 3.74609 1.43551C3.74609 0.642701 3.10338 0 2.31055 0C1.51772 0 0.875 0.642701 0.875 1.43551C0.875 2.22832 1.51772 2.87103 2.31055 2.87103Z" fill="#535353"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="header__burger-info_lang h4">
              <!--<a href="#" @click="setLocale('ru')" class="header__burger-info_lang-item h5" :class="{ 'header__language_item--active' : this.$i18n.locale == 'ru' }">ru</a>
              <a href="#" @click="setLocale('en')" class="header__burger-info_lang-item h5" :class="{ 'header__language_item--active' : this.$i18n.locale == 'en' }">en</a>-->
            </div>
          </div>
        </div>
      </div>
    </header>

    <transition name="fade">
      <router-view
        @colorChange='onColorChange'
        @themeClass='onThemeClass'
        @headerDisplay='onHeaderDisplay'
        @navActiveButton='onNavActiveButton'
        @headerBottomBorder='onHeaderBottomBorder'
      />
    </transition>
  </div>
</template>

<style lang="scss">
  @import './css/main.scss';
</style>

<script>
import router from './router'

export default {
  data () {
    return {
      topOffset: 0,
      changeStatus: false,
      changeStatusFix: false,
      headerClass: '',
      bodyOverflow: '',
      headerDisplay: true,
      menuActive: false,
      navActiveButton: '',
      headerStatus: true,
      headerBottomBorder: true,
      headerDisplayDelay: 0
    }
  },
  computed: {},
  methods: {
    offsetCounter () {
      this.topOffset = window.pageYOffset
    },
    onColorChange (data) {
      this.changeStatus = data.changeStatus
      this.changeStatusFix = data.changeStatusFix
    },
    onThemeClass (data) {
      this.headerClass = `header${data.themeClass}`
    },
    setLocale (locale) {
      import(`./langs/${locale}.json`).then((msgs) => {
        this.$i18n.setLocaleMessage(locale, msgs)
        this.$i18n.locale = locale
      })
    },
    toggleMenu () {
      let checkboxEl = document.querySelector('.header__burger_toggle')
      let menuBgEl = document.querySelector('.header__burger_bg')

      if (checkboxEl.checked === false) {
        document.body.style.overflow = 'hidden'

        if (menuBgEl) {
          this.menuActive = true
        }
      } else {
        document.body.style.overflow = ''

        if (menuBgEl) {
          this.menuActive = false
        }
      }
    },
    onHeaderDisplay (data) {
      if (data) {
        if (data.headerDisplayDelay === 0) {
          this.headerDisplay = data.headerDisplay
          this.headerDisplayDelay = data.headerDisplayDelay
        } else {
          setTimeout(() => {
            this.headerDisplay = data.headerDisplay
            this.headerDisplayDelay = data.headerDisplayDelay
          }, data.headerDisplayDelay * 1000)
        }
      }
    },
    onHeaderBottomBorder (data) {
      if (data) {
        this.headerBottomBorder = data.headerBottomBorder
      }
    },
    directLink (path) {
      path = `/${path}`
      let checkboxEl = document.querySelector('.header__burger_toggle')
      this.menuActive = false

      document.body.style.overflow = ''

      if (checkboxEl) {
        checkboxEl.checked = false
      }

      router.push({ path }, () => {})
    },
    onNavActiveButton (data) {
      if (data) {
        this.navActiveButton = data.navActiveButton
      }
    }
  },
  beforeMount () {
    window.history.scrollRestoration = 'manual'
  },
  mounted () {
    window.history.scrollRestoration = 'manual'
    this.offsetCounter()

    window.addEventListener('scroll', () => {
      this.offsetCounter()
    })
  },
  destroyed () {
    window.addEventListener('scroll', () => {
      this.offsetCounter()
    })
  }
}
</script>
